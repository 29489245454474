.footerContainer {
    width: 104%;
    background-color: rgba(134, 103, 237, 0.16);
    display: flex;
    flex-direction: column;
    align-items: center;

    &_footer {
        width: 90%;
        color: #000000;
        display: flex;
        justify-content: space-between;
        padding-top: 50px;

        &_first {
            display: flex;
            gap: 7%;
            width: 100%;
            &_part1 {
                flex-direction: column;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                img {
                    cursor: pointer;
                    //margin-top: 20px;
                    //margin-left: 28px;
                }

                .reachUs {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;

                    h4 {
                        font-family: "Segoe UI", sans-serif;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: center;
                        margin-top: 130px;
                    }
                }

                .reachUsImg {
                    padding-left: 84px;
                    img {
                        width: 38px;
                        height: 33px;
                        margin: -7px 14px 0px 14px;
                        cursor: pointer;
                    }
                }

                h2 {
                    font-family: 'PT Serif', serif;
                    font-size: 25px;
                    font-weight: 700;
                    line-height: 40px;
                    text-align: left;
                }
            }

            &_part2 {
                cursor: pointer;

                h2 {
                    font-family: "Segoe UI", sans-serif;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 40px;
                    text-align: left;
                    color: rgba(0, 0, 0, 1);
                    margin-bottom: 20px;
                }

                h4 {
                    font-family: "Segoe UI", sans-serif;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20px; /* Adjusted line-height */
                    text-align: left;
                    color: rgba(34, 43, 84, 1);
                }
            }

            @media (max-width: 500px) {
                flex-direction: column;
                gap: 20px; /* Changed to gap instead of row-gap */
                margin-bottom: 20px;
                width: 90%; /* Adjusted width for smaller screens */
                align-items: center;
            }
        }

        &_part4 {
            cursor: pointer;

            h4 {
                font-size: 20px; /* Adjusted font size */
                font-weight: 700;
                line-height: 30px; /* Adjusted line-height */
                text-align: left;

                @media (max-width: 500px) {
                    text-align: center;
                }
            }

            .input {
                display: flex;
                flex-direction: column;
                align-items: center;

                input {
                    padding: 8px; /* Adjusted padding */
                    width: 100%;
                    border-radius: 10px;
                    border: none;

                    @media (max-width: 500px) {
                        width: 80%;
                    }
                }
            }
        }

        @media (max-width: 500px) {
            flex-direction: column;
            align-items: center;
        }
    }

    p {
        font-size: 16px; /* Adjusted font size */
        font-weight: 100;
        line-height: 24px; /* Adjusted line-height */
        text-align: center;
        color: #0c0b0b;
        padding-bottom: 20px;
        margin-top: 25px; /* Adjusted margin */
    }

    .spanFooter {
        font-family: 'Segoe UI', sans-serif;
        font-weight: 400;
        font-size: 14px; /* Adjusted font size */
        line-height: 20px; /* Adjusted line-height */
        text-align: center;
        color: rgba(33, 37, 41, 1);
        width: 200px; /* Adjusted width */
        height: 20px; /* Adjusted height */
    }
    @media (max-width: 360px) {
        .footerContainer {
            width: 100%; /* Adjusted width for tablets */
        }
        .footerContainer_footer_first_part2{
            margin-right: auto;
      }
    }

    
    @media (max-width: 768px) {
        .footerContainer {
            width: 100%; /* Adjusted width for tablets */
        }
        .footerContainer_footer_first_part2{
            margin-right: auto;
      }
    }

    @media (max-width: 1366px) {
        .footerContainer {
            width: 95%; /* Adjusted width for desktops */
        }
    }

    @media (max-width: 1920px) {
        .footerContainer {
            width: 90%; /* Adjusted width for large desktops */
        }
        
    }
}

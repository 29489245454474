.ContactUs {
    background-image: url('../../assets/contactUs.svg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 204px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-top: 50px;

    .ContactUscontent-left {
        padding: 20px;
        max-width: 50%; // Limit width of content, adjust as needed
        border-radius: 10px;

        p {
            margin-bottom: 77px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 31px;
            line-height: 38.4px;
            color: rgba(33, 37, 41, 1);
            margin-left: 74px;
        }
    }

    .ContactUscontent-right {
        button {
            font-family: 'Segoe UI', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: rgba(27, 26, 46, 1);
            width: 159.59px;
            height: 51px;
            border-radius: 20px;
            border: 2px solid rgba(88, 84, 85, 1);
            background-color: rgba(237, 233, 247, 0.09);
            margin-bottom: 40px;
            margin-right: 65px;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;

        .ContactUscontent-left {
            max-width: 100%;
            text-align: center;
            padding: 0 20px;

            p {
                margin-bottom: 20px;
                margin-left: 0;
                font-size: 24px;
                line-height: 30px;
            }
        }

        .ContactUscontent-right {
            button {
                margin-right: 0;
            }
        }
    }

    @media screen and (max-width: 360px) {
        .ContactUscontent-left {
            p {
                font-size: 20px;
                line-height: 28px;
            }
        }

        .ContactUscontent-right {
            button {
                width: 100%;
                margin-right: 0;
                padding: 15px;
            }
        }
    }
}

.Itservice1 {
  width: 100%;
}

.internalITServicesConatiner {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 35px;
    line-height: 50px;
    margin: 34px 0px -16px 0px;
    text-align: center;
    color: rgb(62, 44, 111);
  }

  h2 {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 35px;
    line-height: 50px;
    color: rgb(62, 44, 111);
    margin: 0; /* Remove margins */
  }

  p {
    font-family: 'Segoe UI', sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    text-align: left;
    color: rgba(72, 88, 150, 1);
  }

  .internalITServicesConatiner_left,
  .internalITServicesConatiner_right {
    display: flex;
    margin-top: 62px;
    width: 100%;
    max-width: 1280px;
    align-items: flex-start; /* Align items at the start */

    img {
      width: 90%; /* Decrease width for smaller screens */
      height: auto; /* Keep height proportional */
    }

    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; /* Start content from the top */
      padding: 20px;

      h2 {
        text-align: left;
      }

      p {
        text-align: left;
      }
    }
  }

  .internalITServicesConatiner_left {
    flex-direction: column;

    .Itservice2 {
      order: 1;
    }

    div {
      order: 2;
    }
  }

  .internalITServicesConatiner_right {
    flex-direction: column;

    .Itservice3 {
      order: 1;
    }

    div {
      order: 2;
    }
  }

  .internalITServicesConatiner_right {
    flex-direction: column;

    .Itservice4 {
      order: 1;
    }

    div {
      order: 2;
    }
  }

  @media (min-width: 360px) {
    .internalITServicesConatiner_left,
    .internalITServicesConatiner_right {
      img {
        width: 90%; /* Decrease width for smaller screens */
        margin-left: 20px;
      }
      p {
        width: 90%;
      }
      h1 {
        margin: 20px 0px 0px 0px;
        text-align: left;
      }
    }
  }

  @media (min-width: 769px) {
    .internalITServicesConatiner_left,
    .internalITServicesConatiner_right {
      flex-direction: column;

      img {
        width: 70%; /* Increase width for larger screens */
        height: auto; /* Keep height proportional */
        margin-bottom: 20px; /* Add space below the image */
      }

      div {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }

  @media (min-width: 1366px) {
    .internalITServicesConatiner_left,
    .internalITServicesConatiner_right {
      flex-direction: row;

      img {
        width: 40%; /* Adjust width for larger screens */
        height: auto; /* Keep height proportional */
      }

      div {
        width: 60%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .internalITServicesConatiner_left {
      .Itservice2 {
        order: 1;
      }

      div {
        order: 2;
        align-items: flex-start; /* Align content vertically */
        margin-top: -32px; /* Adjust margin to align with the image */
      }
    }

    .internalITServicesConatiner_right {
      .Itservice3 {
        order: 2;
      }

      div {
        order: 1;
        align-items: flex-start; /* Align content vertically */
        margin-top: -32px; /* Adjust margin to align with the image */
      }
    }
    .internalITServicesConatiner_left {
      .Itservice4 {
        order: 3;
      }

      div {
        order: 3;
        align-items: flex-start;
        margin-top: -32px; 
      }
    }
  }
}

// $button-font-family: "Segoe UI", sans-serif;
// $dropdown-background-color: rgba(245, 247, 250, 1);
// $dropdown-option-color: rgba(27, 26, 46, 1);
// $dropdown-option-hover-background-color: rgba(253, 222, 159, 1);
// $dropdown-option-hover-color: rgba(27, 26, 46, 1);
// $dropdown-font-size: 14px;
// $dropdown-line-height: 11px;

// .HeaderContainer {
//   width: 100%;
//   height: 80px;
//   background: url('../../assets/Frame1.png') no-repeat;
//   background-size: cover;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   gap: 2em;

//   @media (max-width: 425px) {
//     height: 45px;
   
//   }

//   &_image-container {
//     width: 20%;
//     img {
//       max-height: 100%;
//       object-fit: cover;
//       width: 84%;
//       // height:91.65px;

//       @media (max-width: 768px) {
//         width: 261px;
       
//       }
//       @media (max-width: 425px) {
//         width: 151px;
       
//       }
//     }
//   }

//   &_list-container {
//     height: 100%;
//     width: 80%;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-around;
//     align-items: center;

//     // @media (max-width: 768px) {
//     //   display:none;
//     // }
//     @media (max-width: 768px) {
//       flex-direction: column;
//       position: absolute;
//       height:45%;
//       top: 80px; 
//       width:30%;
//       left: 613;
//       right: 0;
//       background: white; 
//       display: none; 
//       border-radius: 8px;
//       // padding: 1em;
//       box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    
//     }
//     @media(max-width:425px){
//       top: 47px;
//       width: 50%;
//     }

//     &._open {
//       display: flex; 
//     }

//     // &.blur {
//     //   filter: blur(5px);
//     // }
    
  
//    &_list{ 
//     position: relative;    
//     &:hover {
//       border-bottom: 4px solid rgba(62, 44, 111, 1);
//     }
    
//    }


//          .dropdown,
//           .dev-dropdown,
//           .quality-dropdown,
//           .erp-dropdown {
//             position: absolute;
//             top: 126%;
//             background-color: rgb(245, 247, 250);
//             box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//             border-radius: 7px;
//             padding: 8px 0px;
//             display: flex;
//             flex-direction: column;
//             width: 144px;
//             text-align: left;

//             &::after {
//                           content: "";
//                           position: absolute;
//                           top: -15px;
//                           right: 10px;
//                           border-width: 8px;
//                           border-style: solid;
//                           border-color: transparent transparent $dropdown-background-color transparent;
//                         }
              
//                         div {
//                           padding: 8px 15px;
//                           font-family: $button-font-family;
//                           font-weight: 400;
//                           font-size: $dropdown-font-size;
//                           line-height: $dropdown-line-height;
//                           color: $dropdown-option-color;
//                           cursor: pointer;
//                           border-radius: 5px;
//                           transition: background-color 0.3s, color 0.3s;
              
//                           &:hover {
//                             background-color: $dropdown-option-hover-background-color;
//                             color: $dropdown-option-hover-color;
//                           }
//                         }
            

//                         @media(max-width:425px){
//                           top: 3px;
//                           right: -3px;
//                           z-index: 1;
//                           background-color: #f2f2f2;
//                           border:1px solid grey;
//                         }

//                         @media(max-width:768px){
//                           top: 3px;
//                           right: -3px;
//                           z-index: 1;
//                           background-color:#f2f2f2;
//                           border:1px solid grey;
//                         }

//           }
//     button {
//       width: Fixed (119px)px;
//       height: Fixed (38px)px;
//       padding: 11px 29.14px 13px 27px;
//       border-radius: 20px;
//       border: 2px solid #3E2C6F;
//       background: none;

//     } 

// }

// &_hamburger {
//   display: none; 
//   margin-right:2em;
//   // position:relative;
//   img {
//     cursor: pointer;
//     width: 40px; 
//     height: 40px; 
//   }
//   @media (max-width: 768px) {
    
//     display:block;
    
   
//   }

//   @media (max-width: 425px) {    
//     display:block;
//     margin-right:1em;
//   }


// }
// }



$button-font-family: "Segoe UI", sans-serif;
$dropdown-background-color: rgba(245, 247, 250, 1);
$dropdown-option-color: rgba(27, 26, 46, 1);
$dropdown-option-hover-background-color: rgba(253, 222, 159, 1);
$dropdown-option-hover-color: rgba(27, 26, 46, 1);
$dropdown-font-size: 14px;
$dropdown-line-height: 11px;

.HeaderContainer {
  width: 100%;
  height: 80px;
  background: url('../../assets/Frame1.png') no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;

  @media (max-width: 425px) {
    height: 45px;
  }

  &_image-container {
    width: 20%;
    img {
      max-height: 100%;
      object-fit: cover;
      width: 84%;

      @media (max-width: 768px) {
        width: 261px;
      }
      @media (max-width: 425px) {
        width: 151px;
      }
    }
  }

  &_list-container {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      position: absolute;
      height: 45%;
      top: 80px; 
      width: 30%;
      left: 613;
      right: 0;
      background: white; 
      display: none; 
      border-radius: 8px;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    }

    @media(max-width: 425px) {
      top: 47px;
      width: 50%;
    }

    &._open {
      display: flex;
    }

    &_list {
      position: relative;    
      &:hover {
        border-bottom: 4px solid rgba(62, 44, 111, 1);
      }
    }

    .dropdown,
    .quality-dropdown,
    .erp-dropdown {
      position: absolute;
      top: 126%;
      background-color: $dropdown-background-color;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      padding: 8px 0px;
      display: flex;
      flex-direction: column;
      width: 144px;
      text-align: left;

      &::after {
        content: "";
        position: absolute;
        top: -15px;
        right: 10px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent $dropdown-background-color transparent;
      }

      div {
        padding: 8px 15px;
        font-family: $button-font-family;
        font-weight: 400;
        font-size: $dropdown-font-size;
        line-height: $dropdown-line-height;
        color: $dropdown-option-color;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
          background-color: $dropdown-option-hover-background-color;
          color: $dropdown-option-hover-color;
        }
      }

      @media(max-width: 425px) {
        top: 3px;
        right: -3px;
        z-index: 1;
        background-color: #f2f2f2;
        border: 1px solid grey;
      }

      @media(max-width: 768px) {
        top: 3px;
        right: -3px;
        z-index: 1;
        background-color: #f2f2f2;
        border: 1px solid grey;
      }
    }

    .dev-dropdown {
      position: absolute;
      top: 126%;
      background-color: $dropdown-background-color;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      padding: 8px 0px;
      display: flex;
      flex-direction: column;
      width: 144px;
      text-align: left;

      &::after {
        content: "";
        position: absolute;
        top: -15px;
        right: 10px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent $dropdown-background-color transparent;
      }

      div {
        padding: 8px 15px;
        font-family: $button-font-family;
        font-weight: 400;
        font-size: $dropdown-font-size;
        line-height: $dropdown-line-height;
        color: $dropdown-option-color;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
          background-color: $dropdown-option-hover-background-color;
          color: $dropdown-option-hover-color;
        }
      }

      .tech-stacks-dropdown {
        position: relative;

        &:hover .tech-stacks-submenu {
          display: block;
        }

        .tech-stacks-submenu {
          display: none;
          position: absolute;
          top: 0;
          left: 100%;
          background: $dropdown-background-color;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: 7px;
          padding: 8px 0px;
          width: 144px;
          text-align: left;
  
  
          div {
            padding: 8px 15px;
            font-family: $button-font-family;
            font-weight: 400;
            font-size: $dropdown-font-size;
            line-height: $dropdown-line-height;
            color: $dropdown-option-color;
            cursor: pointer;
            border-radius: 5px;
            transition: background-color 0.3s, color 0.3s;
  
            &:hover {
  
              background-color: $dropdown-option-hover-background-color;
              color: $dropdown-option-hover-color;
            }
          }
        }
      }

     
    }
  }

 button {
   width: 119px;
   height: 38px;
   padding: 2px 24.14px 4px 24px;
   border-radius: 20px;
   border: 2px solid #3E2C6F;
   background: none;
 }
 &_hamburger {
  display: none; 
  margin-right: 2em;
 
  img {
    cursor: pointer;
    width: 40px; 
    height: 40px; 
  }
 
  @media (max-width: 768px) {
    display: block;
  }
 
  @media (max-width: 425px) {    
    display: block;
    margin-right: 1em;
  }
}
}

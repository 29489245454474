@mixin responsive($breakpoint) {
    @if $breakpoint == phone {
      @media (max-width: 360px) { @content; }
    }
    @else if $breakpoint == tablet {
      @media (min-width: 361px) and (max-width: 768px) { @content; }
    }
    @else if $breakpoint == desktop {
      @media (min-width: 769px) and (max-width: 1366px) { @content; }
    }
    @else if $breakpoint == LGdesktop {
      @media (min-width: 1367px) { @content; }
    }
  }
.WhoAreWeConatiner {
    background-color: rgb(245, 247, 250);
    color: rgb(62, 44, 111);
    font-family: "Segoe UI", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    text-align: center;
    padding: 30px;
    margin-top: -31px;
    // @include responsive(tablet) {
    //     margin-top: 80px;
    //  }

    @include responsive(desktop) {
        margin-top: 80px;
     }

    @include responsive(LGdesktop) {
        margin-top: 80px;
    }
   

    h1 {
        margin-bottom: 20px; /* Space between heading and content */
    }
}

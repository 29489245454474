
// @mixin breakpoint($breakpoint) {
//   @if $breakpoint == 360px {
//     @media (max-width: 360px) {
//       @content;
//     }
//   } @else if $breakpoint == 768px {
//     @media (max-width: 768px) {
//       @content;
//     }
//   } @else if $breakpoint == 1366px {
//     @media (max-width: 1366px) {
//       @content;
//     }
//   } @else if $breakpoint == 1920px {
//     @media (max-width: 1920px) {
//       @content;
//     }
//   } @else {
//     @media (max-width: $breakpoint) {
//       @content;
//     }
//   }
// }
@mixin breakpoint($width) {
  @media (max-width: $width) {
    @content;
  }
}

.HeroSection {
  width: 100%;
  // height:505.05px;
  height: 434px ;
  background: url('../../assets/Frame3.png') no-repeat;
  background-size: cover;
  overflow-clip-margin: content-box;
  overflow: clip;

  @media (max-width:768px){
    height: 340px;
  }

  &_content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &_data-container {
      width: 50%;
      display:flex;
      flex-direction: column;
      align-items: center;
      @media(max-width:425px){
        width: 100%;
       }
       @media(max-width:375px){
        width: 100%;
       }

      h1 {
        margin-left: 3em;
        color:#3E2C6F;

        @media(max-width:768px){
          font-size:18px;
        }

        @media(max-width:425px){
          font-size:20px;
          margin-top:4em;
         }
         @media(max-width:375px){
          font-size:20px;
          margin-top:4em;
         }
      }

      button {
        width: Fixed (167px)px;
        height: Hug (48px)px;
        padding: 11px 29.14px 13px 27px;
        border-radius: 20px;
        border: 2px solid #3E2C6F;
        background: none;
        // margin-left: 3em;
        font-family: Segoe UI;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: center;


      }
    }

    &_img-container {
      width: 50%;
      @media(max-width:425px){
       display:none;
      }

      img {
        height: 382px;
        width: 576px;

        @media(max-width:768px){
          width:400px;
          height:250px;
        }
      }
    }

  }
}
@import '../utils/breakpoint.scss';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.WeProvideFeaturesContainer {
    color: rgb(62, 44, 111);
    font-family: "Segoe UI", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    text-align: center;
    margin-top: 50px;
    h1 {
        margin-bottom: 20px; /* Space between heading and content */
    }
}

.feature-section, .feature2-section, .feature3-section, .feature4-section {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin: 20px -128px; /* Reduced margin */
    
    .feature-image, .feature-content,
    .feature2-image, .feature2-content,
    .feature3-image, .feature3-content,
    .feature4-image, .feature4-content {
        flex: 1;
        padding: 5px; /* Reduced padding */
        max-width: 36%;
    }

    .feature-image img, .feature2-image img, .feature3-image img, .feature4-image img {
        max-width: 90%;
        height: auto;
    }

    .feature-content, .feature3-content {
        position: relative; /* Needed for positioning the cornerDownRight image */
        
        h1 {
            font-family: 'Segoe UI', sans-serif;
            font-weight: 501;
            font-size: 35px;
            line-height: 50px;
            margin-bottom: 5px;
            color: rgba(62, 44, 111, 1);
            padding-left: 30px; /* Added space between heading and content */
        }

        p {
            margin: 10px 61px;
            position: relative;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 400;
            font-size: 17px;
            line-height: 35px;
            color: rgba(62, 44, 111, 1);
            &::before {
                content: '';
                position: absolute;
                top: 7px;
                left: -30px; 
                width: 20px; 
                height: 20px; 
                background: url('../../assets/cornerDownRight.svg') no-repeat;
                background-size: contain;
            }
        }

        .know-more-button {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: rgba(62, 44, 111, 1);
            border-radius: 20px;
            border: 2px solid rgba(62, 44, 111, 1);
            padding: 11px 29.14px 13px 27px;
            margin-left: 61px; /* Reduced margin */
            margin-top: 20px;
            background-color: transparent;
        }
    }

    .feature2-content, .feature4-content {
        position: relative; /* Needed for positioning the cornerDownRight image */
        
        h1 {
            font-family: 'Segoe UI', sans-serif;
            font-weight: 501;
            font-size: 35px;
            line-height: 50px;
            margin-bottom: 5px;
            color: rgba(62, 44, 111, 1);
            padding-left: 30px; /* Added space between heading and content */
        }

        p {
            margin: 10px 81px;
            position: relative;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 400;
            font-size: 17px;
            line-height: 35px;
            color: rgba(62, 44, 111, 1);
            &::before {
                content: '';
                position: absolute;
                top: 7px;
                left: -30px; 
                width: 20px;
                height: 20px; 
                background: url('../../assets/cornerDownRight.svg') no-repeat;
                background-size: contain;
            }
        }

        .know-more-button {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: rgba(62, 44, 111, 1);
            border-radius: 20px;
            border: 2px solid rgba(62, 44, 111, 1);
            padding: 11px 29.14px 13px 27px;
            margin-left: 75px;
            margin-top: 20px;
            background-color: transparent;
        }
    }
}

/* Responsive Design */
@media (max-width: 360px) {
    .feature-section, .feature3-section {
        flex-direction: column;
        margin: 10px;
        
        .feature-image, .feature3-image,
        .feature-content, .feature3-content {
            max-width: 100%;
            padding: 0;
        }

        .feature-content h1, .feature3-content h1 {
            font-size: 24px;
            line-height: 32px;
            margin-left: -22px;
        }

        .feature-content p, .feature3-content p {
            margin: 10px;
            font-size: 15px;
            line-height: 25px;
            &::before {
                left: -23px;
            }
        }

        .know-more-button {
            font-size: 16px;
            line-height: 20px;
            margin-left: 0 !important;
            padding: 10px 20px;
        }
    }

    .feature2-section, .feature4-section {
        flex-direction: column-reverse;
        margin: 10px;
        
        .feature2-image, .feature4-image,
        .feature2-content, .feature4-content {
            max-width: 100%;
            padding: 0;
        }

        .feature2-content h1, .feature4-content h1 {
            font-size: 24px;
            line-height: 32px;
            margin-left: -22px;
        }

        .feature2-content p, .feature4-content p {
            margin: 10px;
            font-size: 15px;
            line-height: 25px;
            &::before {
                left: -23px;
            }
        }

        .know-more-button {
            font-size: 16px;
            line-height: 20px;
            margin-left: 0 !important;
            padding: 10px 20px;
        }
    }
}

@media (min-width: 361px) and (max-width: 768px) {
    .feature-section, .feature3-section {
        flex-direction: column;
        margin: 15px;
        
        .feature-image, .feature3-image,
        .feature-content, .feature3-content {
            max-width: 100%;
            padding: 0;
        }

        .feature-content h1, .feature3-content h1 {
            font-size: 28px;
            line-height: 36px;
            margin-left: -20px;
        }

        .feature-content p, .feature3-content p {
            margin: 10px;
            font-size: 16px;
            line-height: 28px;
            &::before {
                left: -23px;
            }
        }
        

        .know-more-button {
            font-size: 16px;
            line-height: 20px;
            margin-left: 0 !important;
            padding: 10px 25px;
        }
    }

    .feature2-section, .feature4-section {
        flex-direction: column-reverse;
        margin: 15px;
        
        .feature2-image, .feature4-image,
        .feature2-content, .feature4-content {
            max-width: 100%;
            padding: 0;
        }

        .feature2-content h1, .feature4-content h1 {
            font-size: 28px;
            line-height: 36px;
            margin-left: -20px;
        }

        .feature2-content p, .feature4-content p {
            margin: 10px;
            font-size: 16px;
            line-height: 28px;
            &::before {
                left: -23px;
            }
        }

        .know-more-button {
            font-size: 16px;
            line-height: 20px;
            margin-left: 0 !important;
            padding: 10px 25px;
        }
    }
}

@media (min-width: 769px) and (max-width: 1366px) {
    .feature-section, .feature2-section, .feature3-section, .feature4-section {
        margin: 20px -100px;
        
        .feature-image, .feature2-image, .feature3-image, .feature4-image,
        .feature-content, .feature2-content, .feature3-content, .feature4-content {
            max-width: 40%;
            padding: 10px;
        }

        .feature-content h1, .feature2-content h1, .feature3-content h1, .feature4-content h1 {
            font-size: 32px;
            line-height: 44px;
        }

        .feature-content p, .feature2-content p, .feature3-content p, .feature4-content p {
            margin: 10px 50px;
            font-size: 16px;
            line-height: 30px;
        }

        .know-more-button {
            font-size: 17px;
            line-height: 22px;
            margin-left: 50px;
            padding: 12px 28px;
        }
    }
}

@media (min-width: 1367px) and (max-width: 1920px) {
    .feature-section, .feature2-section, .feature3-section, .feature4-section {
        margin: 20px -128px;
        
        .feature-image, .feature2-image, .feature3-image, .feature4-image,
        .feature-content, .feature2-content, .feature3-content, .feature4-content {
            max-width: 36%;
            padding: 20px;
        }

        .feature-content h1, .feature2-content h1, .feature3-content h1, .feature4-content h1 {
            font-size: 35px;
            line-height: 48px;
        }

        .feature-content p, .feature2-content p, .feature3-content p, .feature4-content p {
            margin: 10px 60px;
            font-size: 17px;
            line-height: 32px;
        }

        .know-more-button {
            font-size: 18px;
            line-height: 24px;
            margin-left: 60px;
            padding: 13px 30px;
        }
    }
}

@media (min-width: 1921px) {
    .feature-section, .feature2-section, .feature3-section, .feature4-section {
        margin: 20px -128px;
        
        .feature-image, .feature2-image, .feature3-image, .feature4-image,
        .feature-content, .feature2-content, .feature3-content, .feature4-content {
            max-width: 36%;
            padding: 20px;
        }

        .feature-content h1, .feature2-content h1, .feature3-content h1, .feature4-content h1 {
            font-size: 38px;
            line-height: 50px;
        }

        .feature-content p, .feature2-content p, .feature3-content p, .feature4-content p {
            margin: 10px 70px;
            font-size: 18px;
            line-height: 35px;
        }

        .know-more-button {
            font-size: 18px;
            line-height: 24px;
            margin-left: 70px;
            padding: 14px 35px;
        }
    }
}

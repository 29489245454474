.HowCanWeHelp {
    .HowCanWeHelpHeading {
        margin-bottom: 42px;
        text-align: center;
        margin-top: 20px;
    }

    .help-heading {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 35px;
        line-height: 50px;
        text-align: center;
        color: rgba(62, 44, 111, 1);
    }

    .help-subtext {
        font-family: 'Segoe UI', sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px; /* Adjusted line-height for better readability */
        color: rgba(95, 99, 104, 1);
    }

    .HowCanWeHelp-content {
        display: flex;
        flex-direction: column; /* Adjusted for small screens */
        align-items: center; /* Center align content */

        padding: 20px; /* Reduced padding */
    }

    .HowCanWeHelp-left {
        margin-bottom: 20px; /* Adjusted margin */
        text-align: center;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .HowCanWeHelp-right {
        width: 100%; /* Take full width on small screens */
        
        form {
            width: 100%; /* Take full width of parent */

            label {
                font-size: 18px; /* Reduced font size */
                width: auto; /* Adjusted width for responsiveness */
            }

            input,
            textarea {
                width: 100%; /* Take full width */
                margin-bottom: 15px; /* Adjusted margin */
                padding: 12px; /* Increased padding */
                border-radius: 12px;
                font-size: 14px;
                border: 1px solid rgba(196, 195, 195, 1);
            }

            textarea {
                height: 120px; /* Increased height for better visibility */
            }

           
            .HowCanWeHelp-button {
                width: 100%;
                height: 40px;
                max-width: 230px; /* Limit maximum width */
                margin-left: 208px; /* Remove left margin */
                margin-top: 20px; /* Adjusted margin */
                border-radius: 20px;
                border: none;
                background-color: rgb(255, 191, 26);
                font-family: "Segoe UI", sans-serif;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: rgb(255, 255, 255);
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Adjust the shadow color and opacity
              }
          

        }
    }

    // Media Queries for different screen sizes

    @media screen and (min-width: 360px) { /* Phone */
        .HowCanWeHelp-content {
            padding: 30px; /* Increased padding */
        }

        .HowCanWeHelp-right form {
            label {
                font-size: 16px; /* Reduced font size */
            }
        }
        .HowCanWeHelp-right form .HowCanWeHelp-button {
            width: 100%;
            max-width: 105px;
            margin-left: 122px;
        }

    }

    @media screen and (min-width: 768px) { /* Tablet */
       
         .HowCanWeHelp-content {
            flex-direction: row; /* Adjusted back to row layout */
            justify-content: space-around; /* Space around items */
            align-items: center; /* Center align items */
        }

        .HowCanWeHelp-left {
            margin-bottom: 0; /* Removed margin bottom */
            margin-right: 20px; /* Increased margin right */
            text-align: left; /* Left align text */
        }

        .HowCanWeHelp-right {
            width: 50%; /* Take 50% width */
            text-align: left; /* Left align text */
        }
        
        .HowCanWeHelp-right form {
            label {
                font-size: 18px; /* Increased font size */
            }
            
         }
         .HowCanWeHelp-right form .HowCanWeHelp-button {
            width: 100%;
            max-width: 105px;
            margin-left: 122px;
        }
    }

    @media screen and (min-width: 1366px) { /* Desktop */
        .HowCanWeHelp-content {
            padding: 50px; /* Increased padding */
        }

        .HowCanWeHelp-right form {
            label {
                font-size: 20px; /* Increased font size */
            }
        }
    }

    @media screen and (min-width: 1920px) { /* LG Desktop */
        .HowCanWeHelp-content {
            padding: 70px; /* Increased padding */
        }

        .HowCanWeHelp-right form {
            label {
                font-size: 22px; /* Increased font size */
            }
        }
    }
}

.WhyChooseUs {
    background: rgba(245, 247, 250, 1);
    padding: 20px;

    .WhyChooseUsContainer {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;

        .choose-section {
            display: flex;
            width: 100%;
            margin-top: 80px;

            .feature-left {
                margin-left: 143px;

                > div:first-child {
                    align-content: center;
                    margin-left: 107px;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 450;
                    font-size: 35px;
                    line-height: 50px;
                    color: rgba(62, 44, 111, 1);
                }

                .feature-image {
                    img {
                        max-width: 182%;
                        height: auto;
                    }
                }
            }

            .feature-right {
                display: flex;
                flex-direction: column;
                margin-left: 122px;
                width: 41%;

                h3 {
                    margin-bottom: 10px;
                    font-family: 'Segoe UI', Arial, sans-serif;
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 28.8px;
                    color: rgb(34, 43, 84);
                    margin-left: 49px;
                }

                p {
                    margin-bottom: 39px;
                    display: flex;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 28.8px;
                    color: rgba(34, 43, 84, 1);
                    margin-left: 49px;
                }

                img {
                    width: min-content;
                    margin-bottom: -61px;
                    height: 45px;
                }
            }
        }
    }

    @media (max-width: 1920px) {
        .WhyChooseUsContainer {
            .choose-section {
                .feature-left {
                    margin-left: 100px;
                    > div:first-child {
                        margin-left: 148px;
                        font-size: 30px;
                        line-height: 50px;
                    }

                    .feature-image img {
                        max-width: 150%;
                    }
                }

                .feature-right {
                    margin-left: 100px;
                    width: 50%;
                    h3 {
                        font-size: 20px;
                        margin-left: 40px;
                    }

                    p {
                        font-size: 20px;
                        margin-left: 65px;
                    }
                }
            }
        }
    }

    @media (max-width: 1366px) {
        .WhyChooseUsContainer {
            .choose-section {
                .feature-left {
                    margin-left: -17px;
                    > div:first-child {
                        margin-left: 154px;
                        font-size: 28px;
                        line-height: 40px;
                    }

                    .feature-image img {
                        max-width: 130%;
                    }
                }

                .feature-right {
                    margin-left: 80px;
                    width: 55%;
                    h3 {
                        font-size: 18px;
                        margin-left: 35px;
                    }

                    p {
                        font-size: 18px;
                        margin-left: 55px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .WhyChooseUsContainer {
            flex-direction: column;
            align-items: center;
            .choose-section {
                flex-direction: column;
                align-items: center;
                .feature-left {
                    margin-left: 0;
                    > div:first-child {
                        margin-left: 120px;
                        font-size: 24px;
                        line-height: 36px;
                    }

                    .feature-image img {
                        max-width: 100%;
                    }
                }

                .feature-right {
                    margin-left: 0;
                    margin-top: 25px;
                    width: 100%;
                    
                    h3 {
                        font-size: 16px;
                        margin-left: 0;
                    }

                    p {
                        font-size: 16px;
                        margin-left: 40px;
                        margin-top: 2px;
                    }
                }
            }
        }
    }

    @media (max-width: 360px) {
        .WhyChooseUsContainer {
            flex-direction: column;
            align-items: center;

            .choose-section {
                flex-direction: column;
                align-items: center;

                .feature-left {
                    margin-left: 0;
                    > div:first-child {
                        margin-left: 87px;
                        font-size: 20px;
                        line-height: 30px;
                    }

                    .feature-image img {
                        max-width: 100%;
                    }
                }

                .feature-right {
                    margin-left: 0;
                    width: 100%;
                    h3 {
                        font-size: 14px;
                        margin-left: 0;
                    }

                    p {
                        font-size: 14px;
                        margin-left: 41px;
                        margin-top: 23px;
                    }

                    img {
                        height: 35px;
                    }
                }
            }
        }
    }
}

@import '../utils/breakpoint.scss';

$button-font-family: "Segoe UI", sans-serif;
$button-font-weight: 400;
$button-font-size: 17px;
$button-line-height: 27px;
$button-padding: 5px 31px;
$button-border: 2px solid black;
$button-border-radius: 20px;
$button-background-color: transparent;
$button-color: black;
$button-transition: background-color 0.3s, color 0.3s;

$dropdown-background-color: rgba(245, 247, 250, 1);
$dropdown-option-color: rgba(27, 26, 46, 1);
$dropdown-option-hover-background-color: rgba(253, 222, 159, 1);
$dropdown-option-hover-color: rgba(27, 26, 46, 1);
$dropdown-font-size: 14px;
$dropdown-line-height: 11px;

.layout_container {
  overflow-x: hidden;
}

.headerContainer {
  &_left {
    width: 100%;
    height: 682.52px;
    background-image: url('../../assets/landingLogo.svg');
    background-size: cover;
    background-position: center;
    position: relative;

    h1 {
      font-family: 'Rubik', sans-serif;
      font-weight: 700;
      font-size: 35px;
      line-height: 50px;
      color: rgba(62, 44, 111, 1);
      width: 35%;
      margin-top: -107px;
      margin-left: 172px;
    }

    .boy-image {
      width: 500px;
      height: auto;
      position: relative;
      top: 250px;
      left: 897px;
    }

    .company-image {
      width: 200px;
      height: auto;
      position: relative;
      bottom: 262px;
      right: 443px;
    }

    .heading-image {
      height: auto;
      position: relative;
      left: -515px;
      top: 113px;
      width: 43%;
    }

    button {
      font-family: $button-font-family;
      font-weight: $button-font-weight;
      font-size: $button-font-size;
      line-height: $button-line-height;
      padding: 10px 13px;
      border: 2px solid black;
      border-radius: 20px;
      background-color: transparent;
      color: black;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
      margin-left: 166px;
    }

    @include breakpoint(360px) {
      height: auto;

      h1 {
        font-size: 24px;
        line-height: 34px;
        margin-top: 0;
        margin-left: 16px;
        width: 90%;
      }

      .boy-image {
        width: 100%;
        top: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      .company-image {
        width: 120px;
        bottom: 0;
        right: 0;
        left: 10px;
        margin-top: 20px;
      }

      .heading-image {
        left: 0;
        top: 20px;
        width: 90%;
        margin: 0 auto;
      }

      button {
        margin-left: 16px;
        margin-top: 10px;
      }
    }
    
    @include breakpoint(768px) {
      height: auto;

      h1 {
        font-size: 24px;
        line-height: 34px;
        margin-top: 0;
        margin-left: 16px;
        width: 90%;
      }

      .boy-image {
        width: 100%;
        top: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      .company-image {
        width: 120px;
        bottom: 0;
        right: 0;
        left: 10px;
        margin-top: 20px;
      }

      .heading-image {
        left: 0;
        top: 20px;
        width: 90%;
        margin: 0 auto;
      }

      button {
        margin-left: 16px;
        margin-top: 10px;
      }
    }

    @include breakpoint(1366px) {
      height: auto;

      h1 {
        font-size: 30px;
        line-height: 40px;
        margin-top: 0;
        margin-left: 16px;
        width: 50%;
      }

      .boy-image {
        width: 450px;
        top: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      .company-image {
        width: 150px;
        bottom: 0;
        right: 0;
        left: 10px;
        margin-top: 20px;
      }

      .heading-image {
        left: 0;
        top: 20px;
        width: 60%;
        margin: 0 auto;
      }

      button {
        margin-left: 16px;
        margin-top: 10px;
      }
    }

    @include breakpoint(1920px) {
      height: auto;

      h1 {
        font-size: 35px;
        line-height: 50px;
        margin-top: 0;
        margin-left: 172px;
        width: 35%;
      }

      .boy-image {
        width: 500px;
        top: 250px;
        left: 897px;
        height: auto;
      }

      .company-image {
        width: 200px;
        bottom: 262px;
        right: 443px;
        height: auto;
      }

      .heading-image {
        left: -515px;
        top: 113px;
        width: 43%;
        height: auto;
      }

      button {
        margin-left: 166px;
        margin-top: 10px;
      }
    }
  }

  &_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    z-index: 10;

    &_first {
      display: flex;
      align-items: center;
      gap: 20px;

      .border-hover {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        position: relative;

        &:hover {
          border-bottom: 4px solid black;
        }
      }

      button {
        font-family: $button-font-family;
        font-weight: $button-font-weight;
        font-size: $button-font-size;
        line-height: $button-line-height;
        padding: $button-padding;
        border: $button-border;
        border-radius: $button-border-radius;
        background-color: $button-background-color;
        color: $button-color;
        cursor: pointer;
        transition: $button-transition;

        &:hover {
          background-color: black;
          color: white;
        }
      }

      .dropdown, .dev-dropdown, .quality-dropdown, .erp-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $dropdown-background-color;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 20;

        div {
          padding: 5px 10px;
          color: $dropdown-option-color;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;

          &:hover {
            background-color: $dropdown-option-hover-background-color;
            color: $dropdown-option-hover-color;
          }
        }
      }
    }

    .burger-icon {
      display: block;
      font-size: 24px;
      cursor: pointer;
    }

    @include breakpoint(360px) {
      position: relative;
      top: auto;
      right: auto;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 10px;
      
      &_first {
        flex-direction: column;
        gap: 10px;
      }

      .burger-icon {
        display: block;
        margin-top: 10px;
      }
    }

    @include breakpoint(768px) {
      position: relative;
      top: auto;
      right: auto;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 10px;

      &_first {
        flex-direction: column;
        gap: 10px;
      }

      .burger-icon {
        display: block;
        margin-top: 10px;
      }
    }

    @include breakpoint(1366px) {
      position: absolute;
      top: 0;
      right: 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: auto;
      padding: 20px;

      &_first {
        flex-direction: row;
        gap: 20px;
      }

      .burger-icon {
        display: block;
      }
    }

    @include breakpoint(1920px) {
      position: absolute;
      top: 0;
      right: 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: auto;
      padding: 20px;

      &_first {
        flex-direction: row;
        gap: 20px;
      }

      .burger-icon {
        display: block;
      }
    }
  }

  &.sidebar-open {
    .headerContainer_right {
      display: block;

      &_first {
        display: flex;
        flex-direction: column;
      }

      .burger-icon {
        display: block;
      }
    }
  }
}
